// fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://unpkg.com/swiper@8/swiper-bundle.min.css');

body {
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
}
.navbar-dark {
  background-color: #0E0E0E;
  color: #ffffff;
  .navbar-toggler {
    border: none!important;
    color: #ffffff;
  }
}
.page-intro {
  min-height: 50vh;
}
.section-dark, .bg-black {
  background-color: #0E0E0E!important;
  color: #ffffff;
}
.offcanvas {
  width: 100%;
}
.nav-link {
  color: #0E0E0E!important;
}
.offcanvas-end {
  border-left: none;
}
.py-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}
.banner {
  background-color: #0E0E0E;
  padding-top: 90px;
  margin-top: -90px;
  .card {
    border: none;
    border-radius: none ;
  }
  .carousel {
    margin-bottom: -90px;
    .carousel-caption {
      text-align: left;
      h2 {

      }
    }
    .carousel-indicators {
      button {
      height: 20px;
      background-color: transparent;
      color: #ffffff;
      }
    }
  }
}
.borderless {
  border: 0px solid transparent!important;
}
.min-vh-50 {
	min-height: 50vh;
}
.mt-n30, .my-n30 {
  margin-top: -30px!important;
}
.mb-n10, .my-n10 {
  margin-bottom: -10px!important;
}
.pb-130, .py-130 {
  padding-bottom: 130px!important;
}
.pt-100, .py-100 {
  padding-top: 100px!important;
}
.img-zoom {
  overflow: hidden!important;
  img {
  transition: transform .5s ease;
  width:100%;
  &:hover {
    transform: scale(1.1);
  }
}
}
.section-black {
  background-color: #0E0E0E;
  padding-top: 30px;
  padding-bottom: 30px;

  .row-cols-1 {
    margin-top: -200px;
  }

  a {
    .card {
      border-radius: 0;
      border: none;
      color: #0E0E0E;
      text-decoration: none;
      h5 {
        text-decoration: none;
      }
      p {
        text-decoration: none;
      }
      img {
        border-radius: 0;
      }
      .card-footer {
        background-color: #ffffff;
        border: none;
        font-size: 28px;
      }
    }
  }
}
.btn {
  border-radius: 0;
}
.aboutImage {
   margin-top: -200px;
}
.imageEnlarge {
  &:hover{
    cursor: url('https://res.cloudinary.com/njh101010/image/upload/c_scale,w_41/v1649927689/naaman/zoom.png'), auto;	
  }
}
#carouselBlock {
  .carousel-item {
    text-align: center;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  }
}
.overflow-x-overlay {
	overflow: overlay;
}
.gantt {
	display: grid;
	border: 0;
	position: relative;
	min-width: 1000px;
	overflow: scroll;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	&__row {
		display: grid;
		grid-template-columns: 150px 1fr;
		background-color: #fff;
		&:nth-child(odd) {
			background-color: #f5f5f5;
			.gantt__row-first {
				background-color: #f5f5f5;
			}
		}
		&:nth-child(3) {
			.gantt__row-bars {
				border-top: 0;
			}
			.gantt__row-first {
				border-top: 0;
			}
		}
		&--empty {
			background-color: lighten(#7e8f94, 25%) !important;
			z-index: 1;
			.gantt__row-first {
				border-width: 1px 1px 0 0;
			}
		}
		&--lines {
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: transparent;
			grid-template-columns: 150px repeat(12, 1fr);
			span {
				display: block;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
				&.marker {
					background-color: rgba(10, 52, 68, 0.13);
					z-index: 2;
				}
			}
			&:after {
				grid-row: 1;
				grid-column: 0;
				background-color: #1688b345;
				z-index: 2;
				height: 100%;
			}
		}
		&--months {
			color: #fff;
			background-color: #0E0E0E !important;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			grid-template-columns: 150px repeat(12, 1fr);
			.gantt__row-first {
				border-top: 0 !important;
			  background-color: #0E0E0E !important;
			}
			span {
				text-align: center;
				font-size: 13px;
				align-self: center;
				font-weight: bold;
				padding: 20px 0;
			}
		}
		&-first {
			background-color: #fff;
			border-width: 1px 0 0 0;
			border-color: rgba(0, 0, 0, 0.1);
			border-style: solid;
			padding: 15px 0;
			font-size: 13px;
			font-weight: bold;
			text-align: center;
		}
		&-bars {
			list-style: none;
			display: grid;
			padding: 9px 0;
			margin: 0;
			grid-template-columns: repeat(12, 1fr);
			grid-gap: 8px 0;
			border-top: 1px solid rgba(221, 221, 221, 0.8);
			li {
				font-weight: 500;
				text-align: center;
				font-size: 14px;
				min-height: 15px;
				background-color: #55de84;
				padding: 5px 12px;
				color: #fff;
				overflow: hidden;
				position: relative;
				cursor: pointer;
				border-radius: 20px;
				
				&.stripes {
					background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,.1) 5px, rgba(255,255,255,.1) 12px);
				}
				
				&:before,
				&:after {
					content: "";
					height: 100%;
					top: 0;
					z-index: 4;
					position: absolute;
					background-color: rgba(0, 0, 0, 0.3);
				}
				&:before {
					left: 0;
				}
				&:after {
					right: 0;
				}
			}
		}
	}
}